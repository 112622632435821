<template>
  <div class="home">

    <div class="row w-100" style="padding:15px;">

      <div class="player-container col-sm-12 col-md-8">
        <h2 class="stream-title">{{ presentation.name }}</h2>
        <StreamPlayer :code="streamCode"></StreamPlayer>
      </div>

      <div class="col-sm-12 col-md-4">
        <PresentationChat :presentation="presentation"></PresentationChat>

      </div>

      <video id="videoElement"></video>

    </div>


  </div>
</template>

<script>
// @ is an alias to /src
import ITPro_TV_Adam from '@/components/partners/ITPro_TV_Adam';
import Enroll from '@/components/Enroll';
import ChatWindow from 'vue-advanced-chat';
import 'vue-advanced-chat/dist/vue-advanced-chat.css';
import PresentationChat from '@/views/PresentationChat';
import StreamPlayer from '@/components/StreamPlayer_new_alt';

export default {
  name: 'PresentationWatch',
  components: {
    StreamPlayer,
    PresentationChat,
    Enroll,
    ITPro_TV_Adam,
    ChatWindow,
  },
  data: () => {
    return {
      presentation: {},
      messages: [],
      message: '',

      playerElement: undefined,
      player: undefined,

      resolution: undefined,

      edgeCast: '',
      stream: {},
    };
  },
  computed: {
    streamCode: function() {
      return this.$route.params.code;
    },
  },
  created() {
    if (!this.$route.query.registered) {
      return this.$router.push(`/${this.streamCode}`)
    }

    this.$router.replace({
      ...this.$router.currentRoute,
      query: {
        registered: undefined,
      }
    })
  },
  mounted() {
    this.playerElement = document.getElementById('videoElement');

    if (!Hls.isSupported() && !this.playerElement.canPlayType('application/vnd.apple.mpegurl')) {
      this.$toastr.e(
          'Your browser is not currently supported due to technical constraints.',
          'Unable to view this broadcast',
      );
      return;
    }

    this.initiateStream();
  },
  methods: {
    getStreamById() {

      return new Promise((resolve, reject) => {
        this.$http.get(`https://live-api.osidex.stream/streams/${this.streamCode}/code`)
            .then(async response => {

              if (response.status === 200) {
                const stream = response.data;
                this.presentation = stream;
                resolve(stream);
              }
            })
            .catch(exception => {
              reject(exception.response);
            });
      });

    },
    initiateStream() {

      this.getStreamById(this.$route.params.code)
          .then(async stream => {
            this.stream = stream;
          })
          .catch(exception => {

            console.error(exception);

            this.$toastr.e(
                'An error occurred while obtaining the information about this presentation',
                'Failed to load stream',
            );

          });
    },
  },
};
</script>

<style scoped>

.home {
  padding-top: 15px;
}

.stream-title {
  text-align: left;
}

.message-bubble {
  padding: 10px 0px 10px 0px;
}

.message-bubble:nth-child(even) {
  background-color: #F5F5F5;
}

.message-bubble > * {
  padding-left: 10px;
}


.panel-body {
  padding: 0px;
  overflow: scroll;
}

.panel-heading {
  background-color: #3d6da7 !important;
  color: white !important;
}
</style>
