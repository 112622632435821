<template>
  <div>

    <div v-if="failed_to_load">
      <p>{{ failed_to_load }}</p>
    </div>

    <vue-plyr v-else ref="plyr" :options="options">
      <video id="player" autoplay controls crossorigin preload="none">
        <source :src="hlsSource" type="application/x-mpegURL">
      </video>

    </vue-plyr>

  </div>
</template>

<script>
export default {
  name: 'StreamPlayer',
  props: {
    id: '',
    code: '',
  },
  data: () => {
    return {
      stream: {},

      failed_to_load: undefined,
      edge_cast: '',

      player_element: undefined,

      resolution: undefined,

      reconnect_attempts: 0,
      reconnect_delay: 500,

      hlsSource: '',
    };
  },

  computed: {
    streamCode: function() {
      return this.$route.params.code;
    },

    options: function() {

      return {
        //controls: ['play-large', 'play', 'mute', 'volume', 'captions', 'settings', 'pip', 'airplay', 'fullscreen'],
        settings: ['quality', 'captions'],
        autoplay: true,
        clickToPlay: false,

        quality: {
          default: 1080,
          options: [480, 720, 1080],
          forced: true,
          onChange: (e) => this.updateQuality(e),
        },

        fullscreen: {enabled: true, fallback: true, iosNative: true, container: null},
      };
    },
  },
  async created() {

    if (this.id) {
      await this.loadStreamOfId();
    } else if (this.code && !this.id) {
      await this.loadStreamOfCode();
    } else {
      this.failed_to_load = 'No stream id or stream code supplied';
    }

  },
  async mounted() {
    this.player_element = document.getElementById('player');

    await this.initiatePlayer();
  },
  methods: {
    loadStreamOfCode(code) {

      return new Promise((resolve, reject) => {
        this.$http.get(`https://live-api.osidex.stream/streams/${code || this.code}/code`)
            .then(async response => {

              if (response.status === 200) {
                this.stream = response.data;
                resolve(this.stream);
              }
            })
            .catch(exception => {
              reject(exception.response);
            });
      });

    },
    loadStreamOfId() {

      return new Promise((resolve, reject) => {
        this.$http.get(`https://live-api.osidex.stream/streams/${this.id}`)
            .then(async response => {

              if (response.status === 200) {
                this.stream = response.data;
                resolve(this.stream);
              }
            })
            .catch(exception => {
              reject(exception.response);
            });
      });

    },

    async initiatePlayer() {
      await this.loadStreamOfCode(this.streamCode);

      var video = document.getElementById('player');
      var videoSrc = `https://us-east-media-edge.osidex.stream/live/${this.stream._id}/master.m3u8`;

      this.hlsSource = videoSrc;

      if (Hls.isSupported()) {
        // For more Hls.js options, see https://github.com/dailymotion/hls.js
        const hls = new Hls();
        hls.loadSource(videoSrc);
        hls.attachMedia(video);

        window.hls = hls;
      } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
        video.src = videoSrc;
      } else {
        this.failed_to_load = 'Device does not support any of our codecs.';
      }

    },
    updateQuality(newQuality) {

      if (!window.hls) {
        return setTimeout(() => this.updateQuality(newQuality), 1500);
      }

      window.hls.levels.forEach((level, levelIndex) => {
        if (level.height === newQuality) {
          window.hls.currentLevel = levelIndex;
        }
      });
    },
    setQuality(a) {
      this.options.quality = a;
    },
  },
};
</script>
